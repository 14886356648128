<template>
  <div class="container-fluid mt-4 px-md-5">
    <PSidebar
      class="p-sidebar-lg p-sidebar-close bg-transparent"
      :dismissable="true"
      :visible.sync="displayLoginRequiredDialog"
      :position="$screen.breakpoint == 'sm' ? 'full' : 'right'"
      containerStyle="{width: '80vw'}"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 90vh"
      >
        <PCard class="shadow-0 border-0 h-100">
          <template #title>
            <div class="d-flex justify-content-between fix-top">
              <h4>Préréquis pour postuler</h4>
            </div>
            <PDivider class="p-0" />
          </template>
          <template #content>
            <div style="font-size: 1.5rem">
              Vous devez vous connecter avant de postuler.<br />
              <div class="fw-bold">Etes-vous déjà inscrit oui/non?</div>
            </div>
          </template>
          <template #footer>
            <div class="d-flex justify-content-end">
              <PButton
                class="p-button-outlined me-2"
                @click="
                  () => {
                    displayLoginRequiredDialog = false;
                    displayProcessDialog = true;
                  }
                "
                label="Je n'ai pas encore un compte"
              />
              <PButton
                class=""
                label="J'ai un compte"
                @click="
                  $router.push({
                    name: 'espace.de.postuler.offre',
                    params: { offreSlug: activeOffre.id },
                  })
                "
              />
            </div>
          </template>
        </PCard>
      </div>
    </PSidebar>

    <PSidebar
      class="p-sidebar-lg p-sidebar-close bg-transparent"
      :dismissable="true"
      :position="$screen.breakpoint == 'sm' ? 'full' : 'right'"
      :visible.sync="displayProcessDialog"
      :containerStyle="{ width: '70vw' }"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 90vh"
      >
        <PCard class="shadow-0 border-0 w-75">
          <template #header>
            <div class="d-flex justify-content-between m-3 mb-2">
              <h4>Préréquis pour postuler</h4>
            </div>
            <PDivider class="p-0" />
          </template>
          <template #content>
            <div>
              <div class="">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <form ref="form" method="post" @submit.prevent="handleSubmit()">
                    <ValidationProvider
                      rules="required|numeric|available:PHONE|exact:10"
                      v-slot="{ errors }"
                    >
                      <div class="form-floating">
                        <input
                          v-model="candidatInfo.phone"
                          type="text"
                          class="form-control"
                          id="phone"
                          name="phone"
                          :placeholder="`Votre téléphone`"
                        />
                        <label for="phone">Téléphone</label>
                        <!-- <small id="whatsappHelp" class="form-text"
                            >Entrer le numéro sans l'indicatif ({{
                              candidatInfo.nationalite.prefix
                            }}) du {{ candidatInfo.nationalite.nom }}.</small
                          ><br> -->
                        <br />
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required|min:8" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input
                          v-model="candidatInfo.whatsapp"
                          type="text"
                          v-uppercase
                          class="form-control"
                          id="whatsapp"
                          name="whatsapp"
                          placeholder="Votre numéro whatsapp"
                        />
                        <label for="phone">Whatsapp</label>
                        <small id="whatsappHelp" class="form-text"
                          >Ce numéro peut être le même que votre numéro de
                          téléphone.</small
                        ><br />
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </ValidationProvider>
                    <ValidationProvider
                      rules="required|available:EMAIL"
                      v-slot="{ errors }"
                    >
                      <div class="form-floating mb-3">
                        <input
                          v-model="candidatInfo.email"
                          type="email"
                          class="form-control"
                          id="email"
                          name="email"
                          placeholder="Votre email"
                        />
                        <label for="email">Adresse Email</label>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>

                <!-- <div v-else class="" style="font-size: 1.5rem">
                  <p>
                    Vous devez suivre la procédure normale de l'inscription en cliquant sur le  <a :href="subscriptionUrl" class="text-primary">{{subscriptionUrl}}</a>. <br>
                    Pour information l'inscription se fait par étape et se termine par le dépôt de votre CV. <br/>
                    Un conseiller en emploi pourra prendre contact avec vous dans les meilleurs délais.<br/>
                  </p>
                  <div>
                      Si vous avez des questions, nous vous invitons à contacter les numéros suivants :
                    
                      <div>(gratuit) : <span class="fw-bold">+229 01 5110 3333</span></div>
                      <div>(payant) : <span class="fw-bold">+229 01 5110 6666</span></div>
                      <div>Nous sommes à votre service !</div>
                    </div>
                </div> -->
              </div>
            </div>
          </template>
          <template #footer>
            <div class="d-flex justify-content-end">
              <template>
                <PButton
                  class="p-button-outlined me-2"
                  @click.prevent="displayProcessDialog = false"
                  label="Quitter"
                />
                <PButton class="" label="Valider" @click="submitForm" />
              </template>
            </div>
          </template>
        </PCard>
      </div>
    </PSidebar>

    <PSidebar
      class="p-sidebar-lg p-sidebar-close bg-transparent"
      :dismissable="true"
      :visible.sync="displayContactInfo"
      :position="$screen.breakpoint == 'sm' ? 'full' : 'right'"
      :containerStyle="{ width: '70vw' }"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 90vh"
      >
        <PCard class="shadow-0 border-0 w-75">
          <template #content>
            <div>
              <h2 class="text-uppercase text-primary">Information importante !</h2>
              <div class="message-box p-3">
                <div class="" style="font-size: 1.5rem">
                  <div style="font-size: 1.5rem">
                    Vous devez suivre la procédure normale de l'inscription en cliquant
                    sur le
                    <a :href="subscriptionUrl" class="text-primary">{{
                      subscriptionUrl
                    }}</a
                    >. <br />
                    Pour information, l'inscription se fait par étape et se termine par le
                    dépôt de votre CV. <br />
                    Un conseiller en emploi pourra prendre contact avec vous dans les
                    meilleurs délais.<br />
                  </div>
                  <div>
                    Si vous avez des questions, nous vous invitons à contacter les numéros
                    suivants :

                    <div>(gratuit) : <span class="fw-bold">+229 01 5110 3333</span></div>
                    <div>(payant) : <span class="fw-bold">+229 01 5110 6666</span></div>
                    <div>Nous sommes à votre service !</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="d-flex justify-content-end">
              <PButton
                class="p-button-danger"
                label="Fermer"
                @click.prevent="closeInfoBox"
              />
            </div>
          </template>
        </PCard>
      </div>
    </PSidebar>

    <PSidebar
      class="p-sidebar-lg p-sidebar-close"
      :dismissable="true"
      :visible.sync="displayDialog"
      :position="$screen.breakpoint == 'sm' ? 'full' : 'right'"
      containerStyle="{width: '70vw'}"
    >
      <PCard class="shadow-0 border-0">
        <template #title>
          <div class="d-flex justify-content-between fix-top">
            <div>
              <PButton @click="veutPostuler" label="Postuler" class="p-button-rounded" />
              <!-- <PButton icon="pi pi-heart" class="p-button-rounded p-button-help p-button-outlined ms-2  " /> -->
            </div>
            <div>
              <small class="text-caption"
                >{{ activePostIndex + 1 }}/{{ offres.length }}</small
              >
              <span class="p-buttonset ms-2">
                <PButton
                  @click="gotoPreviousPost"
                  class="p-button-sm p-button-outlined"
                  icon="pi pi-chevron-left"
                />
                <PButton
                  :disabled="activePostIndex + 1 == offres.length"
                  @click="gotoNextPost"
                  class="p-button-sm p-button-outlined"
                  icon="pi pi-chevron-right"
                />
              </span>
              <PButton
                @click.stop="displayDialog = false"
                label="Fermer"
                class="p-button-outlined p-button-sm ms-2"
              />
            </div>
          </div>
          <PDivider />
        </template>
        <template #content>
          <OffreDetailViewer v-if="activeOffre != null" :offreId="activeOffre.id" />
        </template>
      </PCard>
    </PSidebar>

    <div class="bg- mt-2 mb-2">
      <!-- <div class="container text-center">
        <div class="col-sm-8 mx-auto">
          <form class="d-flex my-1">
            <input
              class="form-control"
              type="search"
              placeholder="Rechercher une offre"
              aria-label="Search"
              v-model="mxSearch"
            />
            <button type="submit" class="style_btn btn btn-primary">
              <i class="bi bi-search"></i>Rechercher
            </button>
          </form>
        </div>
      </div> -->
      <div class="col-sm-12 col-md-12">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="border-0 shadow-0 rounded-4">
          <div class="row py-0 p- grid formgrid">
            <div class="col field">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <!-- <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                    </span> -->
                  <PInputText
                    type="text"
                    v-model="mxSearch"
                    @keyup.enter.prevent.stop="mxSubmitQuery"
                    :placeholder="'Rechercher par mot(s) clé(s). Ex soudeur'"
                  />
                  <!-- <p-multi-select
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      /> -->
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search me-2"></i>Rechercher</a
                >
              </div>
            </div>
          </div>
          <transition>
            <div class="border-0 shadow-0 mt-2">
              <div class="row p-fluid grid">
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 mb-4"
                    v-if="option.visible"
                    :key="index"
                  >
                    <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :displayMode="option.displayMode"
                      :emitObject="false"
                      :searchOptions="[option.labelField]"
                    />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <slot name="offreSwitcher"></slot>

    <div class="mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="row" v-if="mxLoading">
            <div
              class="field card py-2 border-0 shadow-0 col-12 md:col-6"
              v-for="i in 3"
              :key="i"
            >
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <!-- <PSkeleton width="10rem" height="4rem"></PSkeleton> -->
              <PDivider />
            </div>
          </div>
          <div class="row" v-else>
            <div class="active-filters">
              <div
                class="card shadow-0 border-0 border-bottom-1"
                v-if="mxActiveFiltersCount > 0"
              >
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <h6 class="text-uppercase" v-if="mxActiveFiltersCount > 0">
                      Filtres appliquées:
                    </h6>
                    <template v-for="(option, index) in filterOptions">
                      <!-- <div v-if="option.value != null && option.value.length != 0" :key="index" class="mb-2 border border-primary pt-2"  style="width: 320px"> -->
                      <PTag
                        severity="warning"
                        class="ms-2 mb-2"
                        v-for="(item, i) in option.value"
                        :key="index + '_' + i"
                      >
                        <small class="p-1 text-caption">
                          {{ item[option.labelField] }}
                          <PButton
                            v-tooltip.top="`Retirer du filtre`"
                            icon="pi pi-times cursor-pointer"
                            class="p-button-danger p-button-sm ms-1 p-0"
                            @click.prevent.stop="
                              option.value = option.value.filter((el) => el != item)
                            "
                            style="height: 14px; width: 14px"
                          />
                        </small>
                      </PTag>
                      <!-- </div> -->
                    </template>
                  </div>
                </div>
              </div>
              <!-- <PDivider class="my-0 py-0" /> -->
            </div>

            <div v-if="offres.length == 0">
              <div class="card shadow-0 border-0">
                <div class="card-body">
                  <h6 class="fw-italic">Aucune offre trouvée pour le filtre actif.</h6>
                </div>
              </div>
            </div>
            <div class="shadow-0">
              <div class="card-body bg-white border">
                <h6>
                  Total <span class="h4">{{ mxTotalCount }}</span> offres retrouvées.
                </h6>
              </div>
            </div>
            <div
              class="col-sm-12 col-lg-12 m justify-content-center cursor-pointer"
              v-for="(offre, index) in offres"
              :key="index"
              @click.prevent="showOffre(index)"
            >
              <div class="border-bottom rounded-1 bg-white h-100 px-2 py-4">
                <div class="d-flex align-items-center">
                  <div
                    class="flex-shrink-0 d-flex justify-content-center align-items-center"
                  >
                    <!-- <div
                      class="bg-primary style_level_offre d-flex flex-column justify-content-center align-items-center"
                    >
                      <h6 class="my-0 text-white">{{ index + 1 }}</h6>
                      <span
                        class="text-white my-0"
                        style="font-size: 8px"
                      ></span>
                    </div> -->
                  </div>
                  <div class="flex-grow-1 ms-2">
                    <p class="mb-0 d-flex justify-content-between" v-if="offre.poste">
                      {{ offre.poste.libelle }}

                      <PButton
                        label="Voir détail"
                        icon="pi pi-eye"
                        class="p-button p-button-raised p-button-sm p-button-rounded p-button-outlined"
                      />
                    </p>
                    <small> {{ offre.intitule }} </small>
                    <br />
                    <!-- <div v-html="offre.descriptionTache" style="height: 150px;" class="text-truncate"></div> -->
                    <div class="row">
                      <div class="col-11 mt-1">
                        <small class="text-muted">
                          <strong>Expériences</strong> :
                          <PTag
                            :value="offre.annExp + ' ' + offre.uniteExperience"
                            severity="warning"
                            class="py-1"
                          />
                          | <strong v-if="offre.nature">Contrat</strong> :
                          <PTag
                            v-if="offre.nature"
                            :value="offre.nature.code"
                            severity="warning"
                            class=""
                          />
                          |
                          <strong v-if="offre.dureeHebdomadaire">Temps de travail</strong>
                          :
                          <PTag
                            :value="offre.dureeHebdomadaire"
                            severity="warning"
                            class=""
                          />
                          | <strong v-if="offre.niveaus">Niveaux</strong> :
                          <PTag
                            :value="offre.niveaus.map((niv) => niv.description).join('/')"
                            severity="warning"
                            class=""
                          />
                          <br />
                          <strong>Publiée</strong> :
                          <span class="fst-italic">{{
                            $dayjs(offre.dateDebutPub).fromNow()
                          }}</span>
                          |
                          <strong>Date écheance</strong> :
                          <span class="fst-italic">{{
                            $dayjs(offre.dateEch).format("DD-MM-YYYY")
                          }}</span>
                        </small>
                      </div>
                    </div>
                    <div class="mt-2">
                      <PBadge
                        v-for="(d, i) in offre.domaines"
                        severity="secondary"
                        :key="i"
                        :value="d.nom"
                        class="me-2 py-0"
                      />
                    </div>
                  </div>
                  <!-- <strong v-if="offre.nature">Contrat</strong> : <PBadge severity="info" :value="offre.nature.code" class="" ></PBadge> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div class="mx-auto text-center my-5" v-else>
            <div class="d-flex justify-content-center">
              <div class="spinner-" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="h4 text-uppercase">Les dernières annonces</div>
              <div
                class="annonces-list-item mt-3 border-radius border border-radius-sm"
                v-for="(pub, index) in publicites"
                :key="index"
              >
                <div class="w-100">
                  <img
                    :src="pub.fullImageUrl"
                    width="100%"
                    height="300px"
                    class="img-responsive"
                    alt=""
                  />
                </div>
                <div class="px-3 py-1">
                  {{ pub.titre }}
                </div>
              </div>
              <div
                class="annonces-list-item mt-3 border-radius border border-radius-sm"
                v-if="publicites.length == 0"
              >
                <div
                  class="w-100 fst-italic d-flex justify-content-center align-items-center"
                  style="min-height: 200px"
                >
                  Aucune annonce à afficher
                  <!-- <img :src="pub.fullImageUrl" width="100%" height="300px" class="img-responsive" alt=""> -->
                </div>
                <div class="px-3 py-1">
                  <!-- {{ pub.titre }} -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import AsyncMultiSelect from "../../components/common/AsyncMultiSelect.vue";
import Multiselect from "vue-multiselect";

import OffreDetailViewer from "./OffreDetailViewer.vue";

// import FullCalendar from '@fullcalendar/vue'

export default {
  components: {
    AsyncMultiSelect,
    Multiselect,
    OffreDetailViewer,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      loader: true,
      candidatInfo: {
        phone: null,
        whatsapp: null,
        email: null,
      },
      displayDialog: false,
      displayContactInfo: false,
      displayLoginRequiredDialog: false,
      displayProcessDialog: false,
      showAdvancedFilter: false,
      activePostIndex: 0,
      search: "",
      viewModes: [
        { icon: "pi pi-align-left", value: "left" },
        { icon: "pi pi-align-right", value: "Right" },
        { icon: "pi pi-align-justify", value: "Justify" },
      ],

      searchFields: ["search"],
      searchFieldsOptions: [
        {
          label: "Inititulé du poste",
          value: "search",
          visible: true,
        },
      ],
      filterOptions: [
        // {
        //   column: "annExp",
        //   value: "",
        //   label: "Expériences",
        //   labelField: "label",
        //   valueKey: "id",
        //   queryUrl: "anneeExpriences",
        //   dropdown: true,
        //   displayMode: "mega-menu",
        //   visible: true,
        // },
        // {
        //   column: "langue",
        //   value: "",
        //   label: "Langues",
        //   displayMode: "mega-menu",
        //   dropdown: true,
        //   labelField: "nom",
        //   valueKey: "id",
        //   queryUrl: "ethnies",
        //   visible: true,
        // },
        {
          column: "metier",
          value: "",
          displayMode: "classic",
          label: "Inititulé du poste",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          dropdown: true,
          visible: true,

          // items:,
        },
        {
          column: "niveau",
          value: "",
          label: "Niveaux",
          dropdown: true,
          displayMode: "classic",
          labelField: "code",
          valueKey: "id",
          queryUrl: "niveaux",
          visible: true,
          // items:,
        },
        {
          column: "domaine",
          value: "",
          label: "Domaine",
          displayMode: "classic",
          labelField: "nom",
          valueKey: "id",
          queryUrl: "domaines",
          dropdown: true,
          visible: true,
        },
        {
          column: "nature",
          value: "",
          label: "Nature contrat",
          displayMode: "classic",
          labelField: "code",
          valueKey: "id",
          queryUrl: "natures",
          dropdown: true,
          visible: true,
        },
        // {
        //   column: "departement",
        //   value: "",
        //   labelField: "nom",
        //   displayMode: "mega-menu",
        //   valueKey: "id",
        //   label: "Département",
        //   queryUrl: "departements",
        //   dropdown: true,
        //   visible: true,
        // },
      ],
    };
  },
  created() {
    this.fetchPetiteAnnonces();
    this.mxInitializePaginator({
      queryModel: "offre",
      search: this.search,
      fetcherMethod: "offre/fetchOffresPublished",
      dataGetter: "offre/offres",
      paginationGetter: "offre/pagination",
      autoSubmitQuery: false,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 0,
        rowsPerPage: 20,
        sortingParam: `sort=id,desc`,
      },
      filterOptions: this.filterOptions,
      extraQueryArgsParamBuilder: () => {
        return ``;
      },
      searchFields: this.searchFields,
    });
  },
  watch: {
    activePostIndex(val) {
      if (val >= 0) {
        setTimeout(() => {
          this.updateOffreViewsCount({
            offreId: this.activeOffre.id,
          });
        }, 5);
      }
    },
  },
  computed: {
    ...mapGetters({
      publicites: "portail/publicites",
      offres: "offre/offres",
      domaines: "domaine/domaines",
      departements: "departement/departements",
      natures: "nature/natures",
      niveaux: "niveau/niveaux",
      pieces: "piece/typePieces",
      anneeExpriences: "setting/anneeExpriences",
      romes: "rome/romes",
      ethnies: "ethnie/ethnies",
      offre: "offre/offre",
      entreprises: "entreprise/entreprises",
    }),
    handleInput(e) {},
    activeOffre() {
      if (this.activePostIndex != null) {
        return this.offres[this.activePostIndex];
      }
    },
    emptyEvent() {
      return {};
    },
    subscriptionUrl() {
      const route = this.$router.resolve({ name: "accounts.register.de" });
      const absoluteURL = new URL(route.href, window.location.origin).href;
      return absoluteURL;
    },
  },
  methods: {
    ...mapActions({
      fetchPetiteAnnonces: "portail/fetchPetiteAnnonces",
      fetchOffres: "offre/fetchOffresPublished",
      fetchDomaines: "domaine/fetchDomaines",
      fetchDepartements: "departement/fetchDepartements",
      fetchNiveaux: "niveau/fetchNiveaux",
      fetctNatures: "nature/fetchNatures",
      fetchTypePieces: "piece/fetchTypePieces",
      fetchRomes: "rome/fetchRomes",
      fetchEthnies: "ethnie/fetchEthnies",
      selectoffre: "offre/getOneOffre",
      tentativeDeCandidature: "offre/tentativeDeCandidature",
      updateOffreViewsCount: "offre/updateOffreViewsCount",
      fetchEntreprises2: "entreprise/fetchEntreprises2",
    }),
    ensureUserIsConnected() {
      if (this.$user != null && this.$user.id != null) {
      }
      // this.displayLoginRequiredDialog = false;
      // this.displayProcessDialog = true;
    },
    async submitForm() {
      if (await this.isCompleted) {
        this.tentativeDeCandidature({
          candidatInfo: this.candidatInfo,
          offreId: this.activeOffre.id,
        })
          .then(() => {
            this.displayProcessDialog = false;
            setTimeout(() => {
              this.displayContactInfo = true;
            }, 200);
          })
          .catch((err) => err);
      }
    },
    closeInfoBox() {
      this.displayProcessDialog = false;
      this.displayContactInfo = false;
      this.candidatInfo = {
        phone: null,
        whatsapp: null,
        email: null,
      };
    },
    veutPostuler() {
      if (this.$isUserLogined) {
        this.$router.push({
          name: "espace.de.postuler.offre",
          params: { offreSlug: this.activeOffre.id },
        });
      } else {
        this.displayLoginRequiredDialog = true;
      }
    },
    getItemsOf(getterName) {
      return this.$store.getters[getterName];
    },
    showOffre(postIndex) {
      this.displayDialog = true;
      this.activePostIndex = postIndex;

      // this.updateOffreViewsCount({
      //   offreId: this.activeOffre.id
      // })
    },
    gotoNextPost() {
      this.activePostIndex =
        this.activePostIndex < this.offres.length
          ? this.activePostIndex + 1
          : this.activePostIndex;
      console.log(this.activePostIndex);
    },
    gotoPreviousPost() {
      this.activePostIndex =
        this.activePostIndex > 0 ? this.activePostIndex - 1 : this.activePostIndex;
    },
    getOffres() {
      this.$loading(true);
      this.fetchOffres().then(() => {
        this.$loading(false);
      });
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    handleDateClick: function (arg) {
      this.setActiveEvent({
        ...this.emptyEvent,
        datePrevue: arg.dateStr,
        // datePrevue: new Date(this.$dayjs(arg.dateStr).format('YYYY-MM-DDTHH:mm')),
      });
      this.$refs.activiteEditor.show();
    },
  },
};
</script>

<style>
.p-sidebar-header {
  display: none;
}

.event-box-pending {
  background: #42a5f5;
  background: #66bb6a;
  background: #26c6da;
  background: #7e57c255;
  background: #7e57c245;
  color: #7e57c2 !important;
}

.event-box-done {
  /* background: #66BB6A45;
     color: #66BB6A!important; */
  background: #00b68345;
  color: #00b683 !important;
  color: rgba(0, 0, 0, 0.57) !important;
}
.event-box-danger {
  background: #ffa72645;
  color: #ffa726ff !important;
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-missed {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #fe005345;
  /* color: #FE0053FF!important; */
  /* color: rgba(0, 0, 0, 0.87); */
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-pending {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #485ffd45;
  color: #485ffdff !important;
}

.event-box-reported {
  background: #085e7d45 !important;
  color: #085e7d !important;
  background: #df711b45 !important;
  color: #df711b !important;
}

.event-box-total {
  background: #22006945 !important;
  color: #220069 !important;
}

.event-box {
  font-weight: 430;
  /* color: #222222!important; */
}

.message-box {
  background: #ffc10778 !important;
}

.annonces-list-item {
  border-radius: 15px;
}
</style>
